%link {
  display: flex;
  align-items: center;
  padding-left: 1.8rem;
  // justify-content: center;
}

/* User Layout -> Header Nav links */
.user-header-nav-link {
  @extend %link;
  width: 230px;
  height: 40px;
  color: var(--white);
  opacity: 0.5;

  &--active {
    opacity: 1;
    font-weight: bold;
  }
}
/* END OF User Layout -> Header Nav links */

/* User Layout -> Courses Nav links */
.user-courses-nav-link {
  @extend %link;
  padding: 0 10px;
  color: var(--accent-3);

  &--active {
    color: var(--black);
  }
}
/* END OF User Layout -> Courses Nav links */
