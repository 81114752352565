/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
/* END OF Fonts */

/* Colors */
:root {
  --primary-base: #800020;
  --secondary-base: #800020;
  --primary-hover: #8000209d;

  --secondary-05: #ffc5d336;
  --secondary-1: #ffc5d3;
  --secondary-2: #ff93ae;
  --secondary-3: #fc5780;
  --secondary-4: #dd3a63;
  --secondary-5: #bd1f46;
  --secondary-6: #9b203f;
  --secondary-7: #5a051a;
  --secondary-8: #3c0311;
  --secondary-9: #1d060b;

  --accent-1: #e8e8e8;
  --accent-2: #c1c9d2;
  --accent-3: #697386;
  --accent-4: #7fffdf;

  --white: #ffffff;
  --black: #000000;
}
/* END OF Colors */

* {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-base);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-05);
  }
}

@media screen and (max-width: 500px) {
  * {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-base);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--secondary-05);
    }
  }
}

.disabled-link {
  pointer-events: none;
}

/* Override Material-ui DateAndTimePickers */
.date-time-picker .MuiInputBase-root,
.date-time-picker .MuiFormControl-root,
.date-time-picker input {
  width: 100% !important;
  /* max-width: 100% !important;
  min-width: 100% !important; */
}
.date-time-picker .MuiInputBase-root::after,
.date-time-picker .MuiInputBase-root::before {
  content: none !important;
  border-bottom: none !important;
}
.date-time-picker input {
  height: 40px !important;
  border: 1px solid var(--accent-2) !important;
  border-radius: 2px !important;
  padding: 0 16px !important;
}

.date .MuiInputBase-input.MuiInput-input {
  border: none !important;
}
/* END OF Override Material-ui DateAndTimePickers */

/* Carousel styles */
.rec.rec-arrow {
  border-radius: 0;
  background-color: white;
  border: none;
  box-shadow: none;
}
.rec.rec-arrow:hover:enabled {
  background-color: white;
  color: black;
  box-shadow: none;
}
.rec .rec-arrow:disabled {
  visibility: hidden;
}
.rec .rec-dot_active:hover {
  box-shadow: 0 0 1px 3px #333;
  background-color: #333;
}
.rec .rec-dot:hover {
  box-shadow: 0 0 1px 3px #333;
}
.rec .rec-dot:focus {
  cursor: pointer;
  box-shadow: 0 0 1px 3px #333;
}
.rec .rec-dot_active {
  box-shadow: 0 0 1px 3px #333;
  background-color: #333;
}
.categories-dropdown_menu {
  position: absolute;
  top: 50px;
  right: -35px;
  z-index: 100;
  box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 2px solid rgba(84, 95, 125, 0.1);
  border-radius: 8px;
  line-height: 16px;
  width: 200px;
  height: 150px;
  gap: 10px;
  overflow-y: auto;
  display: flex;
  padding-top: 8px;
  padding-left: 5px;
  flex-direction: column;
  p {
    background: rgba(196, 196, 196, 0.3);
    padding: 10px;
    &:hover {
      background: rgba(90, 125, 192, 0.6);
    }
  }
}
.drop-active {
  opacity: 1;
  visibility: visible;
}
.drop-inactive {
  opacity: 0;
  visibility: hidden;
}
.course-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.course-pagination_num {
  list-style: none;
  display: flex;
  gap: 5px;
  p {
    padding: 10px 15px;
    border: 1px solid #000;
    cursor: pointer;
  }
}
.course-pagination_active {
  background-color: #800020;
  color: #fff;
}
.categories_list {
  &:hover {
    background-color: #80002093;
    color: #fff;
  }
}
