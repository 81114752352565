.course-box-card {
  display: flex;
  opacity: 1;
  flex-direction: column;
  // height:400px // TODO: make responsive;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &--disabled {
    // background-color: var(--accent.1);
    opacity: 0.5;
  }

  &:hover {
    transform: scale(0.992);
    box-shadow: 0px 0px 3px 1px rgba(51, 51, 51, 0.056);
  }

  &__image::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(var(--black), transparent 30%);
    opacity: 0.5;
  }
}
