.main {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

h1 {
    margin: 10px;
    font-size: 40px;
    color: rgb(1, 1, 59);
}

.search {
    width: 30%;
}

ul li {
    font-size: 20px;
} 