#downloadWrapper {
    padding: 20px;
  }
   #certificateWrapper {
    box-shadow: 0 0 5px #000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
   #certificateWrapper img {
    display: block;
  }
   #certificateWrapper p {
    font-family: "Staatliches", cursive;
    margin: 0;
    position: absolute;
    left: 90px;
    top: 300px;
    font-size: 40pt;
  }